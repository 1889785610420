document.querySelectorAll('input[type="file"]').forEach(function(input) {
    let label = input.nextElementSibling;

    input.addEventListener('change', function(e) {
        let totalSize = 0;
        this.files.forEach((file) => {
            totalSize += file.size;
        });

        if(input.hasAttribute('data-maximum-size') && totalSize > input.getAttribute('data-maximum-size')) {
            Toast.error(input.getAttribute('data-maximum-size-error-message'));
            input.value = null;

            return;
        }

        let fileName = this.files && this.files.length > 1 ?
            ('{count} fichiers sélectionnés').replace('{count}', this.files.length):
            e.target.value.split('\\').pop()
        ;

        label.innerHTML = fileName ? fileName : defaultContent;
    });
});
